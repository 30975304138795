<template>
  <cs-page-setting name="logout" ref="page">
    <div class="mx-auto" style="width: 360px">
      <p class="logout-tips text-center fw-bold">
        账号注销成功后 <br />其下所有服务、数据及隐私信息将会被删除并将
        <a class="text-primary">无法恢复</a>
      </p>
      <a-button
        block
        type="primary"
        @click="nextClick"
        style="margin-top: 100px"
        v-click.timeout="3"
        >确定</a-button
      >
    </div>
  </cs-page-setting>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    nextClick() {
      const { page } = this.$refs;
      this.api.service
        .uac_oapi_sso_user_cancelUserAccount({ authCode: page.authCode }, {})
        .then(async ({ code, msg }) => {
          await this.api.toast({ code, msg }, 0);
          this.$refs.page.next();
        });
    },
  },
};
</script>
<style lang="less" scoped>
.logout-tips {
  font-size: 14px;
  color: #333333;
}
</style>
